import { IMvpFilters } from "../../../api/dto/mvp/MvpType";
import { changeDataFormatToISO } from "../../../common/helpers/changeDateFormatToISO";

export const generateODataQueryMvp = (filterData: IMvpFilters) => {
  const sizePage = 10;
  let oDataQuery = "$count=true";

  if (filterData.pagination) {
    const skipValue = filterData.pagination * sizePage - sizePage;
    oDataQuery += `&$skip=${skipValue}&$top=${sizePage}`;
  }

  if (filterData.mvpType || filterData.status || filterData.date || filterData.teamId) {
    const filters: string[] = [];

    if (filterData.mvpType === "season") {
      filters.push(`season ne null`);
    }

    if (filterData.mvpType === "match") {
      filters.push(`match ne null`);
    }

    if (filterData.mvpType === "month") {
      filters.push(`month ne null`);
    }

    if (filterData.status) {
      filters.push(`Status eq '${filterData.status}'`);
    }
    if (filterData.teamId) {
      filters.push(`MainMvpTeam/Id eq ${filterData.teamId}`);
    }

    if (filterData.date) {
      filters.push(`(startVoting ge ${changeDataFormatToISO(filterData.date[0], true)}
      and endVoting le ${changeDataFormatToISO(filterData.date[1])})`);
    }

    oDataQuery += `&$filter=${filters.join(" and ")}`;
  }

  if (filterData.sorting) {
    oDataQuery += `&$orderby=${filterData.sorting}`;
  }
  // else {
  //   oDataQuery += `&$orderby=createdUtc desc`;
  // }

  return oDataQuery;
};
