import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, Modal, Table } from "antd";
import { Option } from "antd/lib/mentions";
import { debounce } from "lodash";
import { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BestPlayerEntity, IMvpFilters, MvpType } from "../../api/dto/mvp/MvpType";
import { routePaths } from "../../common/constants/routePaths";
import { useAppDispatch } from "../../core/redux/store";
import { getBestPlayerList, getTeamsForMvp, mvpRemove } from "../../modules/mvp/bestPlayerActionAsync";
import { bestPlayerCountSelector, bestPlayerListSelector, teamsSelector } from "../../modules/mvp/bestPlayerSelectors";
import { ContentStyled, FiltersButtonBlock, HeaderStyled, TitleStyled } from "../../ui/commonComponents";
import { FiltersHeaderThreeSelectsDate } from "../../ui/customFilters/FiltersHeaderThreeSelectsDate";
import { generateColumnsMvp } from "../../ui/tableColumnsGenerator/mvp/generateColumnsMvp";
import { onChangeDataTable, onChangePaginationTable } from "../../common/helpers/tablesPropsHelpers";
import { SorterResult } from "antd/lib/table/interface";

const MvpForm = lazy(async () =>
  import("./components/MvpForm").then((module) => ({
    default: module.MvpForm
  }))
);

const initialValues: IMvpFilters = {
  date: null,
  pagination: 1,
  pageSize: 10,
  sorting: "StartVoting desc"
};

export const MvpLayout = ({ access }: { access: boolean }) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mvpFilters, setMvpFilters] = useState<IMvpFilters>(initialValues);
  const mvpData = useSelector(bestPlayerListSelector);
  const mvpDataCount = useSelector(bestPlayerCountSelector);
  const teams = useSelector(teamsSelector);

  const handleForm = (key: MvpType, id: string) => {
    navigate(`edit/${id}`);
  };

  const deleteModal = (id: string) => {
    Modal.confirm({
      title: "Вы действительно хотите удалить?",
      maskClosable: true,
      content: "Акция будет удалена навсегда.",
      okText: t("common.buttonsText.confirm"),
      cancelText: t("common.buttonsText.cancel"),
      onOk: () => {
        dispatch(mvpRemove(id)).then(() => setMvpFilters(initialValues));
      }
    });
  };

  const handleRemove = (id: string) => {
    deleteModal(id);
  };

  const columns = generateColumnsMvp({ access, handleForm, handleRemove });

  const changeFilters = useCallback(
    debounce((nameField: string, value: unknown) => {
      setMvpFilters((prev) => ({ ...prev, [nameField]: value }));
    }, 400),
    [setMvpFilters]
  );

  const menu = () => (
    <Menu>
      <Menu.Item key={1} onClick={() => navigate(`${routePaths.form.create}/match`)}>
        {t("specialOffers.mvp.playerOfMatch")}
      </Menu.Item>

      <Menu.Item key={2} onClick={() => navigate(`${routePaths.form.create}/month`)}>
        {t("specialOffers.mvp.playerOfMonth")}
      </Menu.Item>

      <Menu.Item key={3} onClick={() => navigate(`${routePaths.form.create}/season`)}>
        {t("specialOffers.mvp.playerOfSeason")}
      </Menu.Item>
    </Menu>
  );

  const mvpOptions = useMemo(() => {
    const options = [
      { label: t("specialOffers.mvp.playerOfMatch"), value: "match" },
      { label: t("specialOffers.mvp.playerOfMonth"), value: "month" },
      { label: t("specialOffers.mvp.playerOfSeason"), value: "season" }
    ];

    return options.map((option) => (
      <>
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      </>
    ));
  }, []);

  const statusOptions = useMemo(() => {
    const options = [
      { label: t("specialOffers.mvp.statuses.published"), value: "Published" },
      { label: t("specialOffers.mvp.statuses.planned"), value: "Planned" },
      { label: t("specialOffers.mvp.statuses.finished"), value: "Completed" }
    ];

    return options.map((option) => (
      <>
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      </>
    ));
  }, []);

  const teamsOptions = useMemo(
    () =>
      teams.map(({ id: teamId, name }) => (
        <>
          <Option key={teamId} value={String(teamId)}>
            {name}
          </Option>
        </>
      )),
    [teams]
  );

  console.log(mvpFilters);

  useEffect(() => {
    dispatch(getTeamsForMvp());
    dispatch(getBestPlayerList(mvpFilters));
  }, [mvpFilters, location.pathname]);

  return (
    <>
      {/* {isLoading ? <Loader /> : ""} */}
      <HeaderStyled>
        <TitleStyled level={4}>{t("specialOffers.mvp.title")}</TitleStyled>
        <ButtonsWrapper>
          {access && (
            <Button type="primary" icon={<ReloadOutlined />} loading={reload}>
              {t("common.buttonsText.refresh")}
            </Button>
          )}
          {access && (
            <Dropdown trigger={["click"]} overlay={menu()}>
              <Button type="primary" icon={<DownOutlined />} loading={reload}>
                {t("common.buttonsText.create")}
              </Button>
            </Dropdown>
          )}
        </ButtonsWrapper>
      </HeaderStyled>
      <ContentStyled>
        <Card>
          <FiltersButtonBlock>
            <FiltersHeaderThreeSelectsDate
              selectOptions1={mvpOptions}
              selectPlaceholder1="Выберите акцию"
              selectName1="mvpType"
              selectValue1={mvpFilters.mvpType}
              selectOptions2={statusOptions}
              selectPlaceholder2="Выберите статус"
              selectName2="status"
              selectValue2={mvpFilters.status}
              selectOptions3={teamsOptions}
              selectPlaceholder3="Выберите команду"
              selectName3="teamId"
              selectValue3={mvpFilters.teamId}
              dateName="date"
              dateValue={mvpFilters.date}
              datePlaceholder={["Начальная дата", "Конечная дата"]}
              onChange={changeFilters}
              resetFilters={() => setMvpFilters(initialValues)}
              selectShowSearch
            />
          </FiltersButtonBlock>
          <Table
            onChange={(pagination, filters, sorter: SorterResult<BestPlayerEntity> | SorterResult<BestPlayerEntity>[]) =>
              onChangeDataTable<BestPlayerEntity, any>(pagination, sorter, filters, setMvpFilters)
            }
            pagination={onChangePaginationTable(mvpDataCount || 0, mvpFilters.pagination)}
            columns={columns}
            dataSource={mvpData || []}
            scroll={{ x: 1120 }}
            sticky
          />
        </Card>
      </ContentStyled>
      <Routes>
        {access && [
          <Route key="1" path={`${routePaths.form.create}/*`} element={<MvpForm />} />,
          <Route key="2" path={`edit/${routePaths.form.edit()}`} element={<MvpForm />} />
        ]}
      </Routes>
    </>
  );
};

const ButtonsWrapper = styled.div`
  gap: 10px;
  display: flex;
`;
