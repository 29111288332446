import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BestPlayer,
  BestPlayerEntity,
  BestPlayerFiltersType,
  BestPlayerResponce,
  IMvpFilters,
  SeasonsForMvp,
  Team,
  VotingEntity,
  VotingMatch
} from "../../api/dto/mvp/MvpType";
import { validationSearchUserName } from "../../common/helpers/validationSearchUserName";
import {
  fetchMvpByFilter,
  fetchMvpById,
  getMvpPlayers,
  getMvpSeason,
  getMvpTeams,
  getMvpVotingMatches,
  removeMvp,
  saveMvpVoting
} from "../../api/requests/mvp";

export const getBestPlayerList = createAsyncThunk<BestPlayerResponce, IMvpFilters>(
  "bestPlayer/byFilter",
  async (filters, { rejectWithValue }) => {
    return fetchMvpByFilter(filters).catch((error) => rejectWithValue({ error }));
  }
);

export const getBestPlayer = createAsyncThunk<BestPlayerEntity, BestPlayerEntity["id"]>(
  "bestPlayer/byId",
  async (id, { rejectWithValue }) => fetchMvpById(id).catch((error) => rejectWithValue({ error }))
);

export const saveVoting = createAsyncThunk<void, { voting: VotingEntity }>("bestPlayer/publish", async (data, { rejectWithValue }) =>
  saveMvpVoting(data.voting).catch((error) => rejectWithValue({ error }))
);

export const deleteBestPlayer = createAsyncThunk<void, BestPlayerEntity["id"]>("bestPlayer/delete", async (id, { rejectWithValue }) =>
  removeMvp(id).catch((error) => rejectWithValue({ error }))
);

export const getVotingMatches = createAsyncThunk<VotingMatch[], undefined>("bestPlayer/getVotingMatches", async (_, { rejectWithValue }) =>
  getMvpVotingMatches().catch((error) => rejectWithValue({ error }))
);
export const getSeasonForMvp = createAsyncThunk<SeasonsForMvp[], number>(
  "bestPlayer/GetSeasonForMvp",
  async (teamId, { rejectWithValue }) => getMvpSeason(teamId).catch((error) => rejectWithValue({ error }))
);

export const getPlayersForMvp = createAsyncThunk<BestPlayer[], number>("bestPlayer/GetPlayersForMvp", async (teamId, { rejectWithValue }) =>
  getMvpPlayers(teamId).catch((error) => rejectWithValue({ error }))
);

export const getTeamsForMvp = createAsyncThunk<{ teams: Team[] }, undefined>("bestPlayer/GetTeamsForMvp", async (_, { rejectWithValue }) =>
  getMvpTeams().catch((error) => rejectWithValue({ error }))
);

export const mvpRemove = createAsyncThunk<undefined, string>("bestPlayer/RemoveMvp", async (id, { rejectWithValue }) =>
  removeMvp(id).catch((error) => rejectWithValue({ error }))
);
