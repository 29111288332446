import { Button, DatePicker } from "antd";
import { FiltersHeader } from "./FiltersHeaderContainer";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../CustomSelect";
import { Moment } from "moment";

const { RangePicker } = DatePicker;

interface IProps {
  selectOptions1: JSX.Element[];
  selectName1: string;
  selectValue1?: string;
  selectPlaceholder1: string;
  selectOptions2: JSX.Element[];
  selectName2: string;
  selectValue2?: string;
  selectPlaceholder2: string;
  selectOptions3: JSX.Element[];
  selectName3: string;
  selectValue3?: string;
  selectPlaceholder3: string;
  selectShowSearch?: boolean;
  datePlaceholder?: [string, string];
  dateName: string;
  dateValue: null | [Moment, Moment];
  onChange(name: string, value: string | [Moment, Moment]): void;
  resetFilters(): void;
}

export const FiltersHeaderThreeSelectsDate = ({
  selectOptions1,
  selectName1,
  selectValue1,
  selectPlaceholder1,
  selectOptions2,
  selectName2,
  selectValue2,
  selectPlaceholder2,
  selectOptions3,
  selectName3,
  selectValue3,
  selectPlaceholder3,
  selectShowSearch,
  datePlaceholder,
  dateName,
  dateValue,
  onChange,
  resetFilters
}: IProps) => {
  const { t } = useTranslation();

  const changeValues = (nameField: string, value: string | [Moment, Moment]) => {
    onChange(nameField, typeof value === "string" ? value.trim() : value);
  };

  return (
    <>
      <FiltersHeader>
        <CustomSelect
          showSearch={selectShowSearch}
          style={{ width: "inherit" }}
          placeholder={selectPlaceholder1}
          value={selectValue1}
          onChange={(value) => changeValues(selectName1, typeof value === "string" ? value : "")}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {selectOptions1}
        </CustomSelect>
        <CustomSelect
          showSearch={selectShowSearch}
          style={{ width: "inherit" }}
          placeholder={selectPlaceholder3}
          value={selectValue3}
          onChange={(value) => changeValues(selectName3, typeof value === "string" ? value : "")}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {selectOptions3}
        </CustomSelect>
        <RangePicker
          inputReadOnly={true}
          onChange={(e) => changeValues(dateName, e as [Moment, Moment])}
          placeholder={datePlaceholder}
          value={dateValue}
        />
        <CustomSelect
          showSearch={selectShowSearch}
          style={{ width: "inherit" }}
          placeholder={selectPlaceholder2}
          value={selectValue2}
          onChange={(value) => changeValues(selectName2, typeof value === "string" ? value : "")}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {selectOptions2}
        </CustomSelect>
        <Button onClick={resetFilters}>{t("common.filters.clearFilters")}</Button>
      </FiltersHeader>
    </>
  );
};
