import { generateODataQueryMvp } from "../../core/oDataQueryBuilders/mvp/generateODataQueryMvp";
import { get, post, remove } from "../baseRequest";
import { BestPlayer, BestPlayerEntity, IMvpFilters, SeasonsForMvp, Team, VotingEntity, VotingMatch } from "../dto/mvp/MvpType";

export const fetchMvpByFilter = async (filter: IMvpFilters) => {
  const { value: items, ["@odata.count"]: count } = await get(
    `/advertisement/odata/AdminMvpVoting?${generateODataQueryMvp(filter)}&api-version=1.0`
  );

  return { items, count };
};

export const fetchMvpById = async (id: BestPlayerEntity["id"]) => {
  const { value }: { value: BestPlayerEntity[] } = await get(`/advertisement/odata/AdminMvpVoting?api-version=1.0&$filter=Id eq ${id}`);

  return {
    ...value[0],
    MatchId: value[0]?.match?.id,
    PlayerIds: value[0]?.variants.map(({ player }) => player.id)
  };
};

export const saveMvpVoting = async (item: VotingEntity) =>
  await post(`/advertisement/AdminMvpVoting/Save`, JSON.stringify(item), "application/json");

export const removeMvp = async (id: BestPlayerEntity["id"]) => await remove(`/advertisement/AdminMvpVoting/Delete?id=${id}`);

export const getMvpVotingMatches = async (): Promise<VotingMatch[]> => get(`/advertisement/AdminMvpVoting/GetMatchesForMvp`);

export const getMvpSeason = async (teamId: number): Promise<SeasonsForMvp[]> =>
  get(`/advertisement/AdminMvpVoting/GetSeasonsForMvp?api-version=1.0&teamId=${teamId}`);

export const getMvpPlayers = async (teamId: number): Promise<BestPlayer[]> =>
  get(`/mobile/Player/Players?api-version=1.0&teamId=${teamId}`);

export const getMvpTeams = async (): Promise<{ teams: Team[] }> => get(`/mobile/Match/FilterTeams?api-version=1.0`);
