import { createSlice } from "@reduxjs/toolkit";
import { BestPlayer, BestPlayerEntity, SeasonsForMvp, VotingMatch } from "../../api/dto/mvp/MvpType";
import {
  getBestPlayer,
  getBestPlayerList,
  getPlayersForMvp,
  getSeasonForMvp,
  getTeamsForMvp,
  getVotingMatches,
  mvpRemove
} from "./bestPlayerActionAsync";

export interface BestPlayerState {
  isLoading: boolean;
  count: number;
  items?: BestPlayerEntity[];
  entity?: BestPlayerEntity;
  players: BestPlayer[];
  matches: VotingMatch[];
  seasons: SeasonsForMvp[];
  teams: any[];
}

export const bestPlayerInitialState: BestPlayerState = {
  isLoading: false,
  count: 0,
  players: [],
  matches: [],
  seasons: [],
  teams: []
};

export const { actions: bestPlayerActions, reducer: bestPlayerReducer } = createSlice({
  name: "bestPlayer",
  initialState: bestPlayerInitialState,
  reducers: {
    resetEntity(state) {
      state.entity = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBestPlayerList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBestPlayerList.fulfilled, (state, { payload }) => {
        state.count = payload.count;
        state.items = payload.items;
        state.isLoading = false;
      })
      .addCase(getBestPlayerList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getBestPlayer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBestPlayer.fulfilled, (state, { payload }) => {
        state.entity = payload;
        //@ts-ignore
        state.players = payload.variants.map(variant => variant.player) || [];
        state.isLoading = false;
      })
      .addCase(getBestPlayer.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getVotingMatches.fulfilled, (state, { payload }) => {
        state.matches = payload;
        state.isLoading = false;
      })

      .addCase(getSeasonForMvp.fulfilled, (state, { payload }) => {
        state.seasons = payload;
        state.isLoading = false;
      })

      .addCase(getPlayersForMvp.fulfilled, (state, { payload }) => {
        state.players = payload;
        state.isLoading = false;
      })

      .addCase(getTeamsForMvp.fulfilled, (state, { payload }) => {
        state.teams = payload.teams;
        state.isLoading = false;
      })
      .addCase(mvpRemove.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mvpRemove.fulfilled, (state, { meta }) => {
        state.players = state.players.filter((player) => player.id !== +meta.arg);
        state.isLoading = false;
      })
      .addCase(mvpRemove.rejected, (state) => {
        state.isLoading = false;
      });
  }
});
