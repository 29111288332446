import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ColumnType } from "antd/es/table";
import moment from "moment";
import styled from "styled-components";
import { BestPlayerEntity, MvpType } from "../../../api/dto/mvp/MvpType";
import { DeleteAction } from "../../ActionsTable";

interface IProps {
  access: boolean;
  handleForm: (key: MvpType, id: string) => void;
  handleRemove: (id: string) => void;
}

export const generateColumnsMvp = ({ access, handleForm, handleRemove }: IProps) => {
  const columnName: ColumnType<BestPlayerEntity> = {
    title: "Название",
    ellipsis: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%",
    render: (_, entity) => {
      return <>{entity.match ? "Игрок матча" : entity.season ? "Игрок сезона" : "Игрок месяца"}</>;
    }
  };
  const columnTeamName: ColumnType<BestPlayerEntity> = {
    title: "Команда",
    dataIndex: ["mainMvpTeam", "name"],
    ellipsis: true,
    sorter: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%"
  };
  const columnParticipants: ColumnType<BestPlayerEntity> = {
    title: "Участников",
    dataIndex: "count",
    ellipsis: true,
    sorter: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%"
  };
  const columnMvp: ColumnType<BestPlayerEntity> = {
    title: "Матч/месяц/сезон",
    dataIndex: ["match", "name"],
    ellipsis: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%"
  };
  const columnDateStart: ColumnType<BestPlayerEntity> = {
    title: "Дата начала",
    dataIndex: "startVoting",
    ellipsis: true,
    sorter: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%",
    render: (date) => {
      return <>{moment(date).format("DD/MM/YYYY HH:mm")}</>;
    }
  };
  const columnDateEnd: ColumnType<BestPlayerEntity> = {
    title: "Дата окончания",
    dataIndex: "endVoting",
    ellipsis: true,
    sorter: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%",
    render: (date) => {
      return <>{moment(date).format("DD/MM/YYYY HH:mm")}</>;
    }
  };
  const columnStatus: ColumnType<BestPlayerEntity> = {
    title: "Статус",
    dataIndex: "status",
    ellipsis: true,
    sorter: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%",
    render: (status: BestPlayerEntity["status"]) => {
      return (
        <>
          {status !== "None" ? (
            <Status status={status}>
              {status === "Planned" ? "Запланировано" : status === "Completed" ? "Завершено" : "Опубликовано"}
            </Status>
          ) : null}
        </>
      );
    }
  };

  const columnActions: ColumnType<BestPlayerEntity> = {
    title: "Действие",
    ellipsis: true,
    showSorterTooltip: false,
    fixed: "left",
    width: "20%",
    render: (_, entity) => {
      return (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleForm(entity.match ? "match" : entity.month ? "month" : "season", entity.id)}
          />
          <DeleteAction type="link" icon={<DeleteOutlined />} onClick={() => handleRemove(entity.id)} />
        </>
      );
    }
  };

  const colums = [columnName, columnTeamName, columnParticipants, columnMvp, columnDateStart, columnDateEnd, columnStatus];
  if (access) {
    colums.push(columnActions);
  }

  return colums;
};

const Status = styled.div<{ status: BestPlayerEntity["status"] }>`
  background-color: ${({ status }) => (status === "Planned" ? "#fff7e6" : status === "Completed" ? "#F5F5F5" : "#ECFFF2")};
  width: fit-content;
  color: ${({ status }) => (status === "Planned" ? "#FA8C16" : status === "Completed" ? "#595959" : "#00FF4C")};
  padding: 0 8px;
  border: 1px solid ${({ status }) => (status === "Planned" ? "#FFD591" : status === "Completed" ? "#D9D9D9" : "#00FF4C")};
  border-radius: 2px;
`;
